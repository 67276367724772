/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed, 
figure, figcaption, footer, header, hgroup, 
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
	vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure, 
footer, header, hgroup, menu, nav, section {
	display: block;
}
body {
	line-height: 1;
}
ol, ul {
	list-style: none;
}
blockquote, q {
	quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
	content: '';
	content: none;
}
table {
	border-collapse: collapse;
	border-spacing: 0;
}


.vectro {
  position: relative;
  -webkit-text-fill-color: transparent;
  -webkit-text-stroke: 0.1px #f1f1f1;
  font-family: 'Righteous', cursive;
  font-size: 160px;
  margin: 150px 0 50px 0;
}

.vectro:after {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  /*-webkit-animation:vectro_effect 0.067s infinite;*/
  background: repeating-linear-gradient(0deg, rgba(255,255,255,0.05) 0px, rgba(255,255,255,0.05) 1px, rgba(255,255,255,0) 1px, rgba(255,255,255,0) 2px);
}

* {
	box-sizing: border-box;
}

.header {
	display: flex;
	flex: 1;
	justify-content: center;
}

.header > * {
  position: relative;
  -webkit-text-fill-color: transparent;
  -webkit-text-stroke: 0.1px #f1f1f1;
  font-family: 'Righteous' !important;
  font-size: calc(2.6vw + 36px);
  margin-left: 12px;
  margin-right: 12px;
  user-select: none;
}

.header > *:after {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  /*-webkit-animation:vectro_effect 0.067s infinite;*/
  /* background: repeating-linear-gradient(0deg, rgba(255,255,255,0.05) 0px, rgba(255,255,255,0.05) 1px, rgba(255,255,255,0) 1px, rgba(255,255,255,0) 2px); */
}

.header > * > * {
  -webkit-background-clip: text;
  background-image: -webkit-linear-gradient(#cfccc3 0%, #FDFCFA 50%, #E8E7E5 51%, #b6b0b1 52%, #E8E9DB 100%);
  -webkit-filter: drop-shadow(0px 0px 5px #2410bc);
}

div::-webkit-scrollbar-track
{
	border-radius: 10px;
	background-color: #151314;
}

div::-webkit-scrollbar
{
	width: 8px;
	background-color: #151314;
}

div::-webkit-scrollbar-thumb
{
	border-radius: 12px;
	background-color: #303030;
}